* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-height: 100vh;
  background: #fff;
}
.container {
  height: 100vh;
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* padding-bottom: 20px; */
}
.wrapper {
  padding-left: 10px;
  padding-right: 10px;
  
}


img {
  user-select: none !important;
  /* Other image styles */
}
button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none !important;
  background: transparent;
  padding: 0px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  color: #fff;
  border-radius: 100px;
  border-color:gray;
  border-width: 0;
  /* background: #ffc107; */
  /* box-shadow: none; 
  outline: none; */
}

.holding {
  background-color: #ffcc00; /* Add your desired holding state styling */
  color: #333;
}
.text-input {
  background: #fff;
  display: flex;
  border-bottom: 1px solid #ccc;
}
.text-input .to-text {
  border-radius: 0px;
  border-left: 1px solid #ccc;
}
.text-input textarea {
  height: 250px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  background: none;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
}
.text-input textarea::placeholder {
  color: #b7b6b6;
}
.controls,
li,
.icons,
.icons i {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.controls {
  list-style: none;
  padding: 12px 15px;
}
.controls .row .icons {
  width: 38%;
}
.controls .row .icons i {
  width: 50px;
  color: #adadad;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease;
  justify-content: center;
}
.controls .row.from .icons {
  padding-right: 15px;
  border-right: 1px solid #ccc;
}
.controls .row.to .icons {
  padding-left: 15px;
  border-left: 1px solid #ccc;
}
.controls .row select {
  color: #333;
  border: none;
  outline: none;
  font-size: 18px;
  background: none;
  padding-left: 5px;
}
.text-input textarea::-webkit-scrollbar {
  width: 4px;
}
.controls .row select::-webkit-scrollbar {
  width: 8px;
}
.text-input textarea::-webkit-scrollbar-track,
.controls .row select::-webkit-scrollbar-track {
  background: #fff;
}
.text-input textarea::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 8px;
}
.controls .row select::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 8px;
  border-right: 2px solid #ffffff;
}
.controls .exchange {
  color: #adadad;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.2s ease;
}
.controls i:active {
  transform: scale(0.9);
}
/* .container button {
  width: 100%;
  padding: 14px;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  font-size: 17px;
  border-radius: 5px;
  background: #ffc107;
} */
